<template>
	<div class="input-described">
		<InputFormPlay
			class="d-flex align-items-center"
			:skeleton="skeleton"
		>
			<InputPlay
				:validation="validation"
				:name="name"
				:w="inputWidth"
				:placeholder="placeholder"
				class="input-form_input-play"
				:type="type"
				:min="min"
				:max="max"
				:disabled="disabled"
				:validadeOnBlur="validateOnBlur"
				:preventNonNumeric="preventNonNumeric"
				:preventDots="preventDots"
				:preventHyphens="preventHyphens"
				:preventLetters="preventLetters"
				:preventSpaces="preventSpaces"
				oninput="oninput"
				@input="$emit('input', $event)"
				:value="value"
				@blur="validateField"
				:skeleton="skeleton"
			/>

			<div class="d-flex flex-column justify-content-center ml-3">
				<TextPlay
					tag="label"
					:for="name"
					size="sm"
					:text="title"
					weight="bold"
					color="var(--fontcolor)"
					class="mb-1"
					:skeleton="skeleton"
				/>
				<TextPlay
					tag="label"
					:for="name"
					size="sm"
					:text="description"
					color="var(--text-color)"
					:skeleton="skeleton"
				/>
			</div>
		</InputFormPlay>
	</div>
</template>

<script>
export default {
	props: {
		name: String,
		title: String,
		description: String,
		placeholder: String,
		validation: [Object, String],
		value: [String, Number],
		inputWidth: String,
		skeleton: Boolean,
		type: {
			type: String,
			default: 'text',
		},
		min: {
			type: [String, Number],
		},
		max: {
			type: [String, Number],
		},
		oninput: String,
		preventNonNumeric: {
			type: Boolean,
			default: false,
		},
		preventDots: {
			type: Boolean,
			default: false,
		},
		preventHyphens: {
			type: Boolean,
			default: false,
		},
		preventLetters: {
			type: Boolean,
			default: false,
		},
		preventSpaces: {
			type: Boolean,
			default: false,
		},
		validateOnBlur: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		validateField() {
			this.$validator.validate(this.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.input-described {
	input: {
		width: 100%;
	}

	@media (max-width: 768px) {
		.tips-container {
			padding: 0;
			margin-top: 1rem;
		}
		.tips {
			width: 100%;
		}
	}
}
</style>
